<template>
  <StaticPage>
    <div class="border">
      <div class="form_wrapper">
        <div class="form_logo">
          <img alt />
        </div>
        <div class="form_box">
          <p class="title">Login Portal</p>
          <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules">
            <div v-if="!needVerification">
              <InputForm
                :label="$t('common.field.email')"
                name="userName_login"
                v-model.trim="loginForm.userName_login"
              >
              </InputForm>
              <InputForm
                :label="$t('common.field.pw')"
                name="password_login"
                v-model="loginForm.password_login"
                type="password"
                show-password
                @keyup.enter.native="loginSubmit"
              >
              </InputForm>
              <p class="forgotPass">
                <router-link to="/forgetPassword" data-testid="forgetPassword">
                  {{ $t('common.button.forgotPw') }}
                </router-link>
              </p>
              <el-form-item class="login_btn">
                <el-button class="purple_button" @click.native="loginSubmit" data-testid="login">
                  {{ $t('common.button.login') }}
                </el-button>
              </el-form-item>
            </div>
            <div class="verification_wrapper" v-else>
              <slide-verify
                :l="45"
                :r="10"
                :w="275"
                :h="155"
                :imgs="imgs"
                ref="slideblock"
                :slider-text="$t('common.verification.toSlide')"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                @again="onAgain"
              ></slide-verify>
            </div>
            <div v-show="msg" class="verfifyMessage">{{ msg }}</div>
          </el-form>
        </div>
      </div>
    </div>
  </StaticPage>
</template>

<script>
import { md5, rsa } from '@/util/encrypt';
import { apiSecurityPassed } from '@/resource';
import helper from '@/util/signinHelper';
import StaticPage from '@/components/template/StaticPage';
import v1 from '@/assets/images/verification/v1.jpg';
import v2 from '@/assets/images/verification/v2.jpg';
import v3 from '@/assets/images/verification/v3.jpg';
import v4 from '@/assets/images/verification/v4.jpg';
import v5 from '@/assets/images/verification/v5.jpg';
import v6 from '@/assets/images/verification/v6.jpg';

export default {
  name: 'loginClient',
  data() {
    return {
      needVerification: false,
      msg: '',
      imgs: [v1, v2, v3, v4, v5, v6],
      loginForm: {
        userName_login: '',
        password_login: ''
      },
      loginFormRules: {
        userName_login: [
          {
            required: true,
            message: this.$t('loginClient.formValidation.emailReq'),
            trigger: 'blur'
          }
        ],
        password_login: [
          {
            required: true,
            message: this.$t('common.formValidation.pwReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  components: { StaticPage },
  computed: {
    failedSecondTime() {
      return this.$store.state.common.failedSecondTime;
    }
  },
  methods: {
    invokeVerification() {
      this.needVerification = true;
    },
    onSuccess() {
      apiSecurityPassed({
        data: rsa('feliscatus:' + Number(new Date()))
      })
        .then(resp => {
          this.needVerification = false;
          this.$store.commit('common/setFailedSecondTime', false);
        })
        .catch(err => {
          console.log(err);
        });
      this.msg = '';
    },
    onFail() {
      this.msg = this.$t('loginClient.failedMasseage');
    },
    onRefresh() {
      this.msg = '';
    },
    //检测到非人为操作滑动时触发的回调函数
    onAgain() {
      this.msg = this.$t('loginClient.failedMasseage');
      this.$refs.slideblock.reset();
    },
    loginSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) this.checkIfAllowed();
      });
    },
    checkIfAllowed() {
      if (!this.failedSecondTime) {
        this.login();
      } else {
        this.invokeVerification();
      }
    },
    login() {
      const userName_login = rsa(this.loginForm.userName_login);
      const password_login = md5(this.loginForm.password_login);
      helper.signIn(userName_login, password_login);
    }
  }
};
</script>
